/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components), {Link} = _components;
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Investigate the smoke"), "\n", React.createElement(_components.p, null, "You grab a light backpack and start hiking towards the smoke. There are only a few beaten trails and you spend a lot of time cutting straight through the underbrush, which is slow and exhausting. You also have to be careful to avoid spiders, ticks and other small animals."), "\n", React.createElement(_components.p, null, "After a few hours you start smelling the smoke combined with a garlic-like, acrid odor. A couple dozen of meters down from the hill you stand on you spot what looks like the entry to a burnt out bunker. Halley says she's unsure whether she really wants to get closer. As you pause to discuss, the wind turns and you notice another smell - almost like rotting flesh."), "\n", React.createElement(Link, {
    to: "../investigate-bunker"
  }, "> Investigate the bunker"), "\n", React.createElement(Link, {
    to: "../keep-your-distance"
  }, "> Look around, but keep your distance"), "\n", React.createElement(Link, {
    to: "../follow-stench"
  }, "> Follow the stench of rotting flesh"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
